import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/(marketing)": [21,[7]],
		"/(marketing)/about": [22,[7]],
		"/(app)/account/forgot-password": [8,[2]],
		"/(app)/account/register": [~9,[2]],
		"/(app)/account/sign-in": [~10,[2]],
		"/(app)/account/sign-out": [~11,[2]],
		"/api/expression": [26],
		"/api/profile": [27],
		"/api/space_invite": [29],
		"/api/space": [28],
		"/api/user_space": [30],
		"/(app)/app": [~12,[3]],
		"/(app)/app/notifications": [~19,[3]],
		"/(app)/app/settings": [~20,[3]],
		"/(app)/app/[space]": [~13,[3,4]],
		"/(app)/app/[space]/e/[expression_id]": [~14,[3,4],[,,5]],
		"/(app)/app/[space]/settings": [~15,[3,4]],
		"/(app)/app/[space]/storms": [~16,[3,4]],
		"/(app)/app/[space]/t/[expression_id]": [~17,[3,4],[,,6]],
		"/(app)/app/[space]/watched": [~18,[3,4]],
		"/(marketing)/contact": [23,[7]],
		"/(marketing)/privacy": [24,[7]],
		"/(marketing)/terms-of-service": [25,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';