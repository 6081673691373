import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import { env } from '$env/dynamic/public';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	enabled: ['true', 'yes', undefined].includes(env?.PUBLIC_SENTRY_ENABLE),
	dsn: 'https://f93bf8673e09e1eb35ae207c0a02c46f@o4506683781873664.ingest.sentry.io/4506683783708672',
	environment: PUBLIC_ENVIRONMENT,
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [replayIntegration()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
